import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import {
    AdditionalAnalysisInfoComponent,
    FlightRequirementsComponent,
    MissionDataComponent,
    RequirementsPanelComponent,
    ZoneInfoComponent,
} from "./components";
import { AdditionalCrewMembersPreviewComponent } from "./components/addictional-crew-members-preview/additional-crew-members-preview.component";
import { AdjacentAreaAndSpaceComponent } from "./components/adjacent-area-and-space/adjacent-area-and-space.component";
import { BadgeGroupComponent } from "./components/badge-group/badge-group.component";
import { BasicDetailsComponent } from "./components/basic-details/basic-details.component";
import { FinalSoraIssuesComponent } from "./components/final-sora-issues/final-sora-issues.component";
import { InitialSoraIssuesComponent } from "./components/initial-sora-issues/initial-sora-issues.component";
import { MissionDataSimpleComponent } from "./components/mission-data/mission-data-simple/mission-data-simple.component";
import { MissionDatesComponent } from "./components/mission-dates/mission-dates.component";
import { MissionDetailsComponent } from "./components/mission-details/mission-details.component";
import { MissionPreviewComponent } from "./components/mission-preview/mission-preview.component";
import { MissionStatusBadgeComponent } from "./components/mission-status-badge/mission-status-badge.component";
import { MissionTimeRangeComponent } from "./components/mission-time-range/mission-time-range.component";
import { OperationalAreaAirspaceRiskComponent } from "./components/operational-area-airspace-risk/operational-area-airspace-risk.component";
import { OperationalAreaGeometryComponent } from "./components/operational-area-geometry/operational-area-geometry.component";
import { PopulationDensityComponent } from "./components/population-density/population-density.component";
import { RiskMitigationMeasuresComponent } from "./components/risk-mitigation-measures/risk-mitigation-measures.component";
import { RouteSideViewComponent } from "./components/route-side-view/route-side-view.component";
import { SoraIssuesWarningComponent } from "./components/sora-issues-warning/sora-issues-warning.component";
import { TacticalRiskMitigationMeasuresComponent } from "./components/tactical-risk-mitigation-measures/tactical-risk-mitigation-measures.component";
import { TrafficAnalysisComponent } from "./components/traffic-analysis/traffic-analysis.component";
import { UavClassesAndCategoriesInfoComponent } from "./components/uav-classes-and-categories-info/uav-classes-and-categories-info.component";
import { AirRiskMitigationsArcAsNumberPipe, TranslateAnalysisIssuePipe } from "./pipes";

@NgModule({
    declarations: [
        MissionDataComponent,
        MissionDataSimpleComponent,
        ZoneInfoComponent,
        TranslateAnalysisIssuePipe,
        FlightRequirementsComponent,
        AdditionalAnalysisInfoComponent,
        RequirementsPanelComponent,
        MissionTimeRangeComponent,
        MissionStatusBadgeComponent,
        PopulationDensityComponent,
        RouteSideViewComponent,
        UavClassesAndCategoriesInfoComponent,
        AdditionalCrewMembersPreviewComponent,
        MissionPreviewComponent,
        MissionDatesComponent,
        BadgeGroupComponent,
        BasicDetailsComponent,
        MissionDetailsComponent,
        OperationalAreaGeometryComponent,
        TrafficAnalysisComponent,
        OperationalAreaAirspaceRiskComponent,
        AirRiskMitigationsArcAsNumberPipe,
        SoraIssuesWarningComponent,
        InitialSoraIssuesComponent,
        RiskMitigationMeasuresComponent,
        TacticalRiskMitigationMeasuresComponent,
        FinalSoraIssuesComponent,
        AdjacentAreaAndSpaceComponent,
    ],
    exports: [
        MissionDataComponent,
        MissionDataSimpleComponent,
        ZoneInfoComponent,
        TranslateAnalysisIssuePipe,
        FlightRequirementsComponent,
        AdditionalAnalysisInfoComponent,
        RequirementsPanelComponent,
        MissionTimeRangeComponent,
        MissionStatusBadgeComponent,
        PopulationDensityComponent,
        RouteSideViewComponent,
        UavClassesAndCategoriesInfoComponent,
        AdditionalCrewMembersPreviewComponent,
        MissionPreviewComponent,
        MissionDatesComponent,
        BadgeGroupComponent,
        BasicDetailsComponent,
        MissionDetailsComponent,
        OperationalAreaGeometryComponent,
        TrafficAnalysisComponent,
        OperationalAreaAirspaceRiskComponent,
        AirRiskMitigationsArcAsNumberPipe,
        SoraIssuesWarningComponent,
        InitialSoraIssuesComponent,
        RiskMitigationMeasuresComponent,
        TacticalRiskMitigationMeasuresComponent,
        FinalSoraIssuesComponent,
        AdjacentAreaAndSpaceComponent,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmSharedMission",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    imports: [
        CommonModule,
        SharedI18nModule,
        LetModule,
        PushModule,
        SharedUiModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatTableModule,
    ],
})
export class SharedMissionModule {}
