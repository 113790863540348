import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { AirspaceElement, AirspaceElementsInfo } from "@dtm-frontend/shared/map/geo-zones";
import { GeoJSON, ItineraryEditorType, MissionPlanRoute, RouteData } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import {
    MissionPlanAnalysisStatus,
    OperationalGeometryData,
    TacticalMitigationPerformanceRequirementProperty,
    TrafficMissionData,
} from "../../models/mission-plan-verification.models";
import {
    AuthorityAcceptationItem,
    MissionCategory,
    MissionContextType,
    MissionDataSimple,
    MissionPlanData,
    MissionPlanRemarks,
    MissionPlanSpecificPermitType,
    MissionProcessingPhase,
    MissionType,
    SoraSettings,
} from "../../models/mission.models";

interface MissionPreviewComponentState {
    isProcessing: boolean;
    missionData: MissionPlanData | undefined;
    route: MissionPlanRoute | undefined;
    flightPurposes: Record<string, string>;
    analysisStatus: MissionPlanAnalysisStatus | undefined;
    operationalGeometry: OperationalGeometryData | undefined;
    nearbyMissionsRouteData: RouteData<TrafficMissionData>[];
    collisionZones: AirspaceElementsInfo | undefined;
    isFlightRequirementsProcessing: boolean;
    selectedZoneId: string | undefined;
    soraSettings: SoraSettings | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-mission-mission-preview[missionData]",
    templateUrl: "./mission-preview.component.html",
    styleUrls: ["./mission-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionPreviewComponent {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set missionData(value: MissionPlanData | undefined) {
        this.localStore.patchState({ missionData: value });
    }

    @Input() public set route(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ route: value });
    }

    @Input() public set flightPurposes(value: Record<string, string> | undefined) {
        this.localStore.patchState({ flightPurposes: value ?? {} });
    }

    @Input() public set analysisStatus(value: MissionPlanAnalysisStatus | undefined) {
        this.localStore.patchState({ analysisStatus: value });
    }

    @Input() public set operationalGeometry(value: OperationalGeometryData | undefined) {
        this.localStore.patchState({ operationalGeometry: value });
    }

    @Input() public set nearbyMissionsRouteData(value: RouteData<TrafficMissionData>[] | undefined) {
        this.localStore.patchState({ nearbyMissionsRouteData: value });
    }

    @Input() public set collisionZones(value: AirspaceElementsInfo | undefined) {
        this.localStore.patchState({ collisionZones: value });
    }

    @Input() public set isFlightRequirementsProcessing(value: BooleanInput) {
        this.localStore.patchState({ isFlightRequirementsProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set soraSettings(value: SoraSettings | undefined) {
        this.localStore.patchState({ soraSettings: value });
    }

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly missionData$ = this.localStore.selectByKey("missionData").pipe(RxjsUtils.filterFalsy());
    protected readonly route$ = this.localStore.selectByKey("route");
    protected readonly isAuthorityAcceptationConfirmed$ = this.missionData$.pipe(
        map(({ remarks }) => this.getAuthorityAcceptationItemWithMessage(remarks)?.isConfirmedByUser)
    );
    protected readonly isSoraApplication$ = this.missionData$.pipe(
        map(
            ({ category, phase }) =>
                (category?.type === MissionCategory.Specific &&
                    category?.specificPermitType === MissionPlanSpecificPermitType.Individual) ||
                phase === MissionProcessingPhase.CaaPermitApplication
        )
    );
    protected readonly missionDataSimple$ = combineLatest([
        this.missionData$,
        this.localStore.selectByKey("route"),
        this.localStore.selectByKey("flightPurposes"),
    ]).pipe(map(([missionData, route, flightPurposes]) => this.prepareMissionDataSimple(missionData, route, flightPurposes)));
    protected readonly analysisStatus$ = this.localStore.selectByKey("analysisStatus");
    protected readonly isAnalysisAvailable$ = this.analysisStatus$.pipe(map((analysisStatus) => !!(analysisStatus && analysisStatus.sora)));
    protected readonly operationalGeometry$ = this.localStore.selectByKey("operationalGeometry");
    private readonly nearbyMissionsRouteData$ = this.localStore
        .selectByKey("nearbyMissionsRouteData")
        .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
    protected readonly nearbyMissions$ = this.nearbyMissionsRouteData$.pipe(
        map((nearbyMissionsRouteData) => this.convertNearbyMissionsRouteDataToTrafficMissionsData(nearbyMissionsRouteData, false))
    );
    protected readonly collisionMission$ = this.nearbyMissionsRouteData$.pipe(
        map((nearbyMissionsRouteData) => this.convertNearbyMissionsRouteDataToTrafficMissionsData(nearbyMissionsRouteData, true))
    );
    protected readonly collisionZones$ = this.localStore.selectByKey("collisionZones");
    protected readonly isFlightRequirementsProcessing$ = this.localStore.selectByKey("isFlightRequirementsProcessing");
    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly soraSettings$ = this.localStore.selectByKey("soraSettings");
    protected readonly isTacticalMitigationPerformanceRequirementsPanelVisible$ = combineLatest([
        this.missionData$,
        this.analysisStatus$,
    ]).pipe(
        map(([missionData, analysisStatus]) => {
            const tacticalMitigationPerformanceRequirementProperty =
                analysisStatus?.sora?.result?.tacticalMitigationPerformanceRequirementProperty;

            return (
                missionData?.flightType === MissionType.BVLOS &&
                tacticalMitigationPerformanceRequirementProperty &&
                tacticalMitigationPerformanceRequirementProperty !== TacticalMitigationPerformanceRequirementProperty.VLOS
            );
        })
    );

    protected readonly MissionProcessingPhase = MissionProcessingPhase;
    protected readonly MissionContextType = MissionContextType;
    protected readonly ItineraryEditorType = ItineraryEditorType;
    protected readonly MissionType = MissionType;

    constructor(private readonly localStore: LocalComponentStore<MissionPreviewComponentState>) {
        this.localStore.setState({
            isProcessing: false,
            missionData: undefined,
            route: undefined,
            flightPurposes: {},
            analysisStatus: undefined,
            operationalGeometry: undefined,
            nearbyMissionsRouteData: [],
            collisionZones: undefined,
            isFlightRequirementsProcessing: false,
            selectedZoneId: undefined,
            soraSettings: undefined,
        });
    }

    protected flyToMissionRoute(): void {
        // TODO will be implemented in REJ-2973
    }

    protected flyToOtherMissionRoute(routeId: string): void {
        // TODO will be implemented in REJ-2973
    }

    protected selectOtherMission(missionId: string): void {
        // TODO will be implemented in REJ-2973
    }

    protected selectZone(zone: AirspaceElement | undefined): void {
        const currentSelectedZoneId = this.localStore.selectSnapshotByKey("selectedZoneId");

        if (currentSelectedZoneId === zone?.id) {
            this.flyToMissionRoute();
            this.localStore.patchState({ selectedZoneId: undefined });

            return;
        }

        this.flyToGeometry(zone?.geometry);
        this.localStore.patchState({ selectedZoneId: zone?.id });
    }

    private getAuthorityAcceptationItemWithMessage(remarks?: MissionPlanRemarks): AuthorityAcceptationItem | undefined {
        const authorityAcceptation = remarks?.authorityAcceptation;

        if (!authorityAcceptation) {
            return;
        }

        return Object.values(authorityAcceptation).find((acceptation: AuthorityAcceptationItem | undefined) => acceptation?.comment);
    }

    private prepareMissionDataSimple(
        missionData: MissionPlanData,
        route: MissionPlanRoute | undefined,
        flightPurposes: Record<string, string>
    ): MissionDataSimple {
        return {
            isRoutePathBased: !!route?.isPathBased,
            flightStartAtMin: missionData.flightStartAtMin,
            flightStartAtMax: missionData.flightStartAtMax,
            flightFinishAtMin: missionData.flightFinishAtMin,
            flightFinishAtMax: missionData.flightFinishAtMax,
            phase: missionData.phase,
            distance: route?.estimatedDistance,
            operatorName: missionData.operatorName,
            pilotName: missionData.pilotName,
            uavName:
                missionData.capabilities.uavName && missionData.capabilities.setupName
                    ? `${missionData.capabilities.uavName} (${missionData.capabilities.setupName})`
                    : undefined,
            uavSerialNumbers: missionData.capabilities.uavSerialNumbers ?? [],
            trackersIdentifiers: missionData.capabilities.trackersIdentifiers ?? [],
            category: missionData.category,
            flightPurpose: {
                nameTranslationKey: missionData.flightPurpose?.id ? flightPurposes[missionData.flightPurpose?.id] ?? "" : "",
                comment: missionData.flightPurpose?.comment ?? undefined,
            },
            additionalCrew: missionData.capabilities.additionalCrew,
        };
    }

    private convertNearbyMissionsRouteDataToTrafficMissionsData(
        nearbyMissionsRouteData: RouteData<TrafficMissionData>[],
        isCollision: boolean
    ) {
        return nearbyMissionsRouteData.reduce<TrafficMissionData[]>(
            (result, item) => (isCollision === item.isCollision && item.data ? [...result, item.data] : result),
            []
        );
    }

    private flyToGeometry(geometry: GeoJSON | undefined) {
        if (!geometry) {
            return;
        }
        // TODO will be implemented in REJ-2973
    }
}
