import {
    MissionPlanRouteFlightZone,
    MissionPlanRouteSection,
    MissionPlanRouteSegment,
    SectionElementResponseBody,
    Waypoint,
} from "@dtm-frontend/shared/ui";
import { DateUtils } from "@dtm-frontend/shared/utils";
import { MissionPlanRoute } from "../../shared";
import { DensityBox } from "../../shared/models/mission.models";
import { FilterConditions, Mission, MissionDetails, Paging, SearchArea, Sorting } from "../models/mission-search.models";
import {
    MissionPlanCaaPermitDetailsResponseBody,
    MissionPlanDataResponseBody,
    MissionPlanRequestPayload,
    MissionPlanRouteResponseBody,
    MissionPlanVerificationResponseBody,
    MissionPlansResponseBody,
} from "./mission-search-api.models";

function convertSortingToMissionPlanRequestPayloadPage(sorting: Sorting, paging: Paging): MissionPlanRequestPayload["page"] {
    const sort: Array<{
        property: string;
        direction: "ASC" | "DESC";
    }> = [];

    if (sorting === Sorting.StartDateAsc) {
        sort.push({
            property: "flightStartAtMin",
            direction: "ASC",
        });
    } else if (sorting === Sorting.StartDateDesc) {
        sort.push({
            property: "flightStartAtMin",
            direction: "DESC",
        });
    }

    return {
        sort,
        page: paging.page,
        size: paging.size,
    };
}

export function convertFilterConditionsAndSortingToMissionPlanRequestPayload(
    filterConditions: FilterConditions | undefined,
    sorting: Sorting,
    paging: Paging
): MissionPlanRequestPayload {
    const listRequest: MissionPlanRequestPayload["listRequest"] = {};

    if (filterConditions?.missionId) {
        listRequest.planId = !filterConditions.missionId.id ? filterConditions.missionId.name : filterConditions.missionId.id;
    }

    if (filterConditions?.missionType) {
        listRequest.flightType = [filterConditions.missionType];
    }

    if (filterConditions?.operator) {
        listRequest.operator = filterConditions.operator.name;
    }

    if (filterConditions?.pilot) {
        listRequest.pilot = filterConditions.pilot.name;
    }

    if (filterConditions?.uav) {
        listRequest.uavSerialNumber = filterConditions.uav.name;
    }

    if (filterConditions?.status) {
        listRequest.processingPhase = [filterConditions.status];
    }

    if (filterConditions?.fromDate) {
        listRequest.flightDateFrom = filterConditions.fromDate.toISOString();
    }

    if (filterConditions?.toDate) {
        listRequest.flightDateTo = filterConditions.toDate.toISOString();
    }

    if (filterConditions?.area === SearchArea.Country) {
        // NOTE: Simple geometry that represents Poland
        listRequest.geometry = {
            type: "Polygon",
            coordinates: [
                [
                    /* eslint-disable no-magic-numbers */
                    [14.069638889, 49.0020432310001],
                    [14.069638889, 55.849716667],
                    [24.150833333, 55.849716667],
                    [24.150833333, 49.0020432310001],
                    [14.069638889, 49.0020432310001],
                    /* eslint-enable no-magic-numbers */
                ],
            ],
        };
    } else if (filterConditions?.area === SearchArea.Custom) {
        listRequest.geometry = filterConditions.customArea?.geometry ?? undefined;
    }

    return {
        listRequest,
        page: convertSortingToMissionPlanRequestPayloadPage(sorting, paging),
    };
}

export function convertMissionPlansResponseBodyToMissions(content: MissionPlansResponseBody[]): Mission[] {
    return content.map((item) => ({
        id: item.planId,
        flightStartAt: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightStartAtMin),
        flightStartAtMin: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightStartAtMin),
        flightStartAtMax: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightStartAtMax),
        flightFinishAt: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightFinishAtMax),
        flightFinishAtMin: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightFinishAtMin),
        flightFinishAtMax: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightFinishAtMax),
        isPathBased: item.pathBased,
        flightType: item.flightType,
        status: item.processingPhase,
        dtmNames: item.dtmNames,
        operatorNumber: item.operatorNumber,
        operatorName: item.operatorName,
        pilotNumber: item.pilotNumber,
        pilotName: item.pilotName,
        uavName: item.uavManufacturer + " " + item.uavModel,
        uavSerialNumbers: item.uavSerialNumbers,
    }));
}

export function convertPlanDataResponsesToMissionDetails(
    planDataResponse: MissionPlanDataResponseBody,
    planVerificationResponse: MissionPlanVerificationResponseBody,
    caaPermitDetailsResponse: MissionPlanCaaPermitDetailsResponseBody
): MissionDetails {
    return {
        id: planDataResponse.plan.id,
        routeId: planDataResponse.plan.route?.id ?? "",
        flightType: planDataResponse.plan.flightType,
        status: planDataResponse.plan.phase,
        dtmNames: planDataResponse.plan.route?.dtmNames ?? [],
        flightStartAtMin: DateUtils.convertStringDateToDateWithoutTimeZone(planDataResponse.plan.flightStartAtMin),
        flightStartAtMax: DateUtils.convertStringDateToDateWithoutTimeZone(planDataResponse.plan.flightStartAtMax),
        flightFinishAtMin: DateUtils.convertStringDateToDateWithoutTimeZone(planDataResponse.plan.flightFinishAtMin),
        flightFinishAtMax: DateUtils.convertStringDateToDateWithoutTimeZone(planDataResponse.plan.flightFinishAtMax),
        phase: planDataResponse.plan.phase,
        distance: 0,
        operatorName: caaPermitDetailsResponse.crew.operatorName,
        pilotName: planDataResponse.plan.capabilities.pilotName ?? caaPermitDetailsResponse.crew.pilotName ?? "?",
        uavName: planDataResponse.plan.uav.manufacturer + " " + planDataResponse.plan.uav.model,
        uavSerialNumbers: planDataResponse.plan.uav.serialNumbers,
        trackersIdentifiers: planDataResponse.plan.capabilities.trackersIdentifiers ?? [],
        category: planDataResponse.plan.category,
        isRoutePathBased: !!planDataResponse.plan.route?.pathBased,
        flightPurposeId: planDataResponse.plan.flightPurpose?.id,
        flightPurposeComment: planDataResponse.plan.flightPurpose?.comment ?? undefined,
        // TODO: flightRequirements will be implemented later in REJ-2904
        flightRequirements: {
            zones: undefined,
            authorityAcceptation: undefined,
            currentPlanAnalysisStatus: undefined,
            formalJustification: undefined,
        },
        isSoraRequired: planVerificationResponse.soraRequired && !!planVerificationResponse.soraStatus,
        populationDensity: {
            peoplePerSquareKilometerAvg: planVerificationResponse.soraResult?.populationDensity?.peoplePerSquareKilometerAvg,
            peoplePerSquareKilometerMax: planVerificationResponse.soraResult?.populationDensity?.peoplePerSquareKilometerMax,
            boxes: {
                [DensityBox.Lowest]: planVerificationResponse.soraResult?.populationDensity?.boxes?.GREEN,
                [DensityBox.Low]: planVerificationResponse.soraResult?.populationDensity?.boxes?.YELLOW,
                [DensityBox.Medium]: planVerificationResponse.soraResult?.populationDensity?.boxes?.RED,
                [DensityBox.High]: planVerificationResponse.soraResult?.populationDensity?.boxes?.VIOLET,
                [DensityBox.Highest]: planVerificationResponse.soraResult?.populationDensity?.boxes?.BLUE,
                [DensityBox.Unknown]: planVerificationResponse.soraResult?.populationDensity?.boxes?.UNKNOWN,
            },
        },
    };
}

function parseWaypointResponseDates(waypoint: Waypoint, waypointIndex: number): Waypoint {
    const prefix = "A";

    return {
        ...waypoint,
        name: `${prefix}${waypointIndex}`,
        estimatedArriveAt: {
            min: new Date(waypoint.estimatedArriveAt.min),
            max: new Date(waypoint.estimatedArriveAt.max),
        },
    };
}

function convertSectionElementResponseBodyToMissionPlanRouteSection(sections?: SectionElementResponseBody[]): MissionPlanRouteSection[] {
    let sectionId = 0;

    return (
        sections?.map((section, index) => {
            const flightZone: MissionPlanRouteFlightZone | undefined = !section.flightZone
                ? undefined
                : {
                      center: parseWaypointResponseDates(
                          section.flightZone.center,
                          sections[index - 1]?.segment ? (sectionId += 2) : ++sectionId
                      ),
                      flightArea: section.flightZone.flightArea,
                      safetyArea: section.flightZone.safetyArea,
                      groundArea: section.flightZone.ground?.area,
                      groundAdjacentArea: section.flightZone.groundAdjacent?.area,
                      stopover: section.flightZone.stopover,
                      isRunway: section.flightZone.runway,
                  };

            const segment: MissionPlanRouteSegment | undefined = !section.segment
                ? undefined
                : {
                      fromWaypoint: parseWaypointResponseDates(section.segment.from, ++sectionId),
                      toWaypoint: parseWaypointResponseDates(section.segment.to, sectionId + 1),
                      flightArea: section.segment.flightArea,
                      safetyArea: section.segment.safetyArea,
                      groundArea: section.segment.ground?.area,
                      groundAdjacentArea: section.segment.groundAdjacent?.area,
                      elevationProfile: section.segment.elevationProfile,
                      distance: section.segment.distance,
                      speed: section.segment.speed,
                      duration: section.segment.duration,
                  };

            return { flightZone, segment, isActive: section.active };
        }) ?? []
    );
}

export function convertMissionPlanRouteResponseBodyToMissionPlanRoute(response: MissionPlanRouteResponseBody): MissionPlanRoute {
    return {
        routeId: response.routeId,
        planId: response.planId,
        missionId: response.missionId,
        elevations: response.elevations,
        estimatedDistance: response.estimatedDistance,
        sections: convertSectionElementResponseBodyToMissionPlanRouteSection(response.sections),
        isPathBased: response.pathBased,
    };
}
