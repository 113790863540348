import { Injectable } from "@angular/core";
import { Params } from "@angular/router";
import {
    ApplicationListItemStatus,
    DssUserRoles,
    FlightZoneApiService,
    FlightZoneApplicationsList,
    FlightZoneApplicationStatus,
    FlightZoneError,
    RestrictionsList,
} from "@dtm-frontend/dss-shared-lib";
import { AuthState } from "@dtm-frontend/shared/auth";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { Feature } from "@turf/helpers/dist/js/lib/geojson";
import { EMPTY, tap } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { FlightZoneListsActions } from "./flight-zone-lists.actions";

export interface FlightZoneListsStateModel {
    error: FlightZoneError | undefined;
    isProcessing: boolean;
    submittedApplicationsList: FlightZoneApplicationsList | undefined;
    submittedModificationsList: FlightZoneApplicationsList | undefined;
    activeRestrictionsList: RestrictionsList | undefined;
    endedRestrictionsList: RestrictionsList | undefined;
    applicationDraftList: FlightZoneApplicationsList | undefined;
    zoneGeoJson: Feature | undefined;
}

const defaultState: FlightZoneListsStateModel = {
    error: undefined,
    isProcessing: false,
    submittedApplicationsList: undefined,
    submittedModificationsList: undefined,
    activeRestrictionsList: undefined,
    endedRestrictionsList: undefined,
    applicationDraftList: undefined,
    zoneGeoJson: undefined,
};

@State<FlightZoneListsStateModel>({
    name: "flightZoneLists",
    defaults: defaultState,
})
@Injectable()
export class FlightZoneListsState {
    @Selector()
    public static error(state: FlightZoneListsStateModel): FlightZoneError | undefined {
        return state.error;
    }

    @Selector()
    public static hasListDataRetrievalError(state: FlightZoneListsStateModel): boolean {
        return !!state.error;
    }

    @Selector()
    public static isProcessing(state: FlightZoneListsStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static submittedApplicationsList(state: FlightZoneListsStateModel): FlightZoneApplicationsList | undefined {
        return state.submittedApplicationsList;
    }

    @Selector()
    public static submittedModificationsList(state: FlightZoneListsStateModel): FlightZoneApplicationsList | undefined {
        return state.submittedModificationsList;
    }

    @Selector()
    public static activeRestrictionsList(state: FlightZoneListsStateModel): RestrictionsList | undefined {
        return state.activeRestrictionsList;
    }

    @Selector()
    public static endedRestrictionsList(state: FlightZoneListsStateModel): RestrictionsList | undefined {
        return state.endedRestrictionsList;
    }

    @Selector()
    public static applicationDraftList(state: FlightZoneListsStateModel): FlightZoneApplicationsList | undefined {
        return state.applicationDraftList;
    }

    @Selector()
    public static zoneGeoJson(state: FlightZoneListsStateModel): Feature | undefined {
        return state.zoneGeoJson;
    }

    constructor(private readonly flightZoneApi: FlightZoneApiService, private readonly store: Store) {
        if (flightZoneApi === undefined) {
            throw new Error("Initialize FlightZoneModule with .forRoot()");
        }
    }

    @Action(FlightZoneListsActions.GetSubmittedApplicationsList, { cancelUncompleted: true })
    public getSubmittedApplicationsList(
        context: StateContext<FlightZoneListsStateModel>,
        action: FlightZoneListsActions.GetSubmittedApplicationsList
    ) {
        const defaultStatuses = [
            FlightZoneApplicationStatus.New,
            FlightZoneApplicationStatus.Rejected,
            FlightZoneApplicationStatus.SentToANSP,
            FlightZoneApplicationStatus.Assigned,
            FlightZoneApplicationStatus.Reviewed,
            FlightZoneApplicationStatus.Accepted,
        ];

        context.patchState({ isProcessing: true });

        return this.flightZoneApi
            .getApplicationsPaginatedList({
                ...action.filterParams,
                ...this.getParamsFromListItemStatus(action.filterParams.status, defaultStatuses),
                myApplication: !this.hasSupervisorPermissions(),
            })
            .pipe(
                tap((submittedApplicationsList) => {
                    context.patchState({
                        submittedApplicationsList,
                        error: undefined,
                        isProcessing: false,
                    });
                }),
                catchError((error) => {
                    context.patchState({
                        error,
                        isProcessing: false,
                    });

                    return EMPTY;
                })
            );
    }

    @Action(FlightZoneListsActions.GetSubmittedModificationsList, { cancelUncompleted: true })
    public getSubmittedModificationsList(
        context: StateContext<FlightZoneListsStateModel>,
        action: FlightZoneListsActions.GetSubmittedModificationsList
    ) {
        const defaultStatuses = [
            FlightZoneApplicationStatus.New,
            FlightZoneApplicationStatus.Assigned,
            FlightZoneApplicationStatus.Reviewed,
            FlightZoneApplicationStatus.Accepted,
        ];

        context.patchState({ isProcessing: true });

        return this.flightZoneApi
            .getRestrictionsModificationsList({
                ...action.filterParams,
                status: defaultStatuses,
                locked: action.filterParams.status && action.filterParams.status !== ApplicationListItemStatus.Correction,
                myApplication: !this.hasSupervisorPermissions(),
            })
            .pipe(
                tap((submittedModificationsList) => {
                    context.patchState({
                        submittedModificationsList,
                        error: undefined,
                        isProcessing: false,
                    });
                }),
                catchError((error) => {
                    context.patchState({
                        error,
                        isProcessing: false,
                    });

                    return EMPTY;
                })
            );
    }

    @Action(FlightZoneListsActions.GetActiveRestrictionsList, { cancelUncompleted: true })
    public getActiveRestrictionsList(
        context: StateContext<FlightZoneListsStateModel>,
        action: FlightZoneListsActions.GetActiveRestrictionsList
    ) {
        context.patchState({ isProcessing: true });

        return this.flightZoneApi.getRestrictionsList({ ...action.filterParams, ended: false, canceled: false }).pipe(
            tap((activeRestrictionsList) => {
                context.patchState({
                    activeRestrictionsList,
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneListsActions.GetEndedRestrictionsList, { cancelUncompleted: true })
    public getEndedRestrictionsList(
        context: StateContext<FlightZoneListsStateModel>,
        action: FlightZoneListsActions.GetEndedRestrictionsList
    ) {
        context.patchState({ isProcessing: true });

        return this.flightZoneApi.getRestrictionsList({ ...action.filterParams, ended: true }).pipe(
            tap((endedRestrictionsList) =>
                context.patchState({
                    endedRestrictionsList,
                    error: undefined,
                    isProcessing: false,
                })
            ),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneListsActions.GetApplicationDraftList, { cancelUncompleted: true })
    public getApplicationDraftList(
        context: StateContext<FlightZoneListsStateModel>,
        action: FlightZoneListsActions.GetApplicationDraftList
    ) {
        context.patchState({ isProcessing: true });

        return this.flightZoneApi
            .getApplicationsPaginatedList({
                ...action.filterParams,
                status: [FlightZoneApplicationStatus.Draft],
                myApplication: !this.hasSupervisorPermissions(),
            })
            .pipe(
                map((applicationDraftList) => {
                    context.patchState({
                        applicationDraftList,
                        error: undefined,
                        isProcessing: false,
                    });
                }),
                catchError((error) => {
                    context.patchState({
                        error,
                        isProcessing: false,
                    });

                    return EMPTY;
                })
            );
    }

    @Action(FlightZoneListsActions.GetApplicationGeoJson)
    public getApplicationGeoJson(context: StateContext<FlightZoneListsStateModel>, action: FlightZoneListsActions.GetApplicationGeoJson) {
        context.patchState({ isProcessing: true, zoneGeoJson: undefined });

        return this.flightZoneApi.getApplicationGeoJson(action.flightZone.id, action.applicationType).pipe(
            map((zoneGeoJson) => {
                context.patchState({
                    zoneGeoJson: {
                        ...zoneGeoJson,
                        properties: {
                            ...zoneGeoJson.properties,
                            name: action.flightZone.title,
                        },
                    },
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightZoneListsActions.GetRestrictionGeoJson)
    public getRestrictionGeoJson(context: StateContext<FlightZoneListsStateModel>, action: FlightZoneListsActions.GetRestrictionGeoJson) {
        context.patchState({ isProcessing: true, zoneGeoJson: undefined });

        return this.flightZoneApi.getRestrictionGeoJson(action.restriction.id).pipe(
            map((zoneGeoJson) => {
                context.patchState({
                    zoneGeoJson: {
                        ...zoneGeoJson,
                        properties: {
                            ...zoneGeoJson.properties,
                            name: action.restriction.zoneNumber,
                            description: action.restriction.title,
                        },
                    },
                    error: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    private getParamsFromListItemStatus(
        listItemStatus: ApplicationListItemStatus,
        defaultApplicationStatuses: FlightZoneApplicationStatus[]
    ): Params {
        switch (listItemStatus) {
            case ApplicationListItemStatus.WaitingForPublication:
                return {
                    status: [
                        FlightZoneApplicationStatus.SentToANSP,
                        FlightZoneApplicationStatus.Assigned,
                        FlightZoneApplicationStatus.Reviewed,
                        FlightZoneApplicationStatus.Accepted,
                    ],
                    locked: true,
                };
            case ApplicationListItemStatus.ForApproval:
                return {
                    status: [FlightZoneApplicationStatus.New],
                    locked: null,
                };
            case ApplicationListItemStatus.Published:
                return {
                    status: [FlightZoneApplicationStatus.Published],
                    locked: true,
                };
            case ApplicationListItemStatus.Rejected:
                return {
                    status: [FlightZoneApplicationStatus.Rejected],
                    locked: true,
                };
            case ApplicationListItemStatus.Correction:
                return {
                    status: [
                        FlightZoneApplicationStatus.SentToANSP,
                        FlightZoneApplicationStatus.Assigned,
                        FlightZoneApplicationStatus.Reviewed,
                    ],
                    locked: false,
                };
            default:
                return {
                    status: defaultApplicationStatuses,
                    locked: null,
                };
        }
    }

    private hasSupervisorPermissions(): boolean {
        return this.store.selectSnapshot(AuthState.roles)?.includes(DssUserRoles.InstitutionSupervisor) ?? false;
    }
}
