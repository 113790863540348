import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { MissionPlanRoute, PageResponseBody } from "@dtm-frontend/shared/ui";
import { Logger, StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, combineLatest, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { MISSION_SEARCH_ENDPOINTS, MissionSearchEndpoints } from "../mission-search.tokens";
import {
    FilterConditions,
    MissionDetails,
    MissionSearchErrorType,
    MissionsListWithPages,
    Paging,
    Sorting,
} from "../models/mission-search.models";
import {
    MissionPlanCaaPermitDetailsResponseBody,
    MissionPlanDataResponseBody,
    MissionPlanRouteResponseBody,
    MissionPlanVerificationResponseBody,
    MissionPlansResponseBody,
} from "./mission-search-api.models";
import {
    convertFilterConditionsAndSortingToMissionPlanRequestPayload,
    convertMissionPlanRouteResponseBodyToMissionPlanRoute,
    convertMissionPlansResponseBodyToMissions,
    convertPlanDataResponsesToMissionDetails,
} from "./mission-search.converters";

@Injectable({
    providedIn: "root",
})
export class MissionSearchApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(MISSION_SEARCH_ENDPOINTS) private readonly endpoints: MissionSearchEndpoints
    ) {}

    public fetchMissions(
        filterConditions: FilterConditions | undefined,
        sorting: Sorting,
        paging: Paging
    ): Observable<MissionsListWithPages> {
        const payload = convertFilterConditionsAndSortingToMissionPlanRequestPayload(filterConditions, sorting, paging);

        return this.httpClient.post<PageResponseBody<MissionPlansResponseBody>>(this.endpoints.getPlans, payload).pipe(
            map((response: PageResponseBody<MissionPlansResponseBody>) => ({
                pages: {
                    pageSize: response.size,
                    pageNumber: response.number,
                    totalElements: response.totalElements,
                },
                content: convertMissionPlansResponseBodyToMissions(response.content),
            })),
            catchError((error) => {
                Logger.captureException(error);

                return throwError(() => ({ type: MissionSearchErrorType.CannotGetMissions }));
            })
        );
    }

    public getMission(missionId: string): Observable<{
        missionDetails: MissionDetails;
        flightPurposes: Record<string, string> | undefined;
    }> {
        return combineLatest([
            this.httpClient.get<MissionPlanDataResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getPlanData, { planId: missionId })
            ),
            this.httpClient.get<MissionPlanVerificationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getPlanVerification, { planId: missionId })
            ),
            this.httpClient.get<MissionPlanCaaPermitDetailsResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getPlanCaaPermitDetails, { planId: missionId })
            ),
        ]).pipe(
            map(([planData, planVerificationResponse, caaPermitDetailsResponse]) => ({
                flightPurposes: planData.flightPurposes?.reduce(
                    (result, flightPurpose) => ({ ...result, [flightPurpose.id]: flightPurpose.name }),
                    {}
                ),
                missionDetails: convertPlanDataResponsesToMissionDetails(planData, planVerificationResponse, caaPermitDetailsResponse),
            })),
            catchError((error) => {
                Logger.captureException(error);

                return throwError(() => ({ type: MissionSearchErrorType.CannotGetMission }));
            })
        );
    }

    public getMissionRoute(routeId: string): Observable<MissionPlanRoute> {
        return this.httpClient.get<MissionPlanRouteResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getRoute, { routeId })).pipe(
            map((route) => convertMissionPlanRouteResponseBodyToMissionPlanRoute(route)),
            catchError((error) => {
                Logger.captureException(error);

                return throwError(() => ({ type: MissionSearchErrorType.CannotGetMissionRoute }));
            })
        );
    }
}
