import { Injectable } from "@angular/core";
import { MAX_PAGE_SIZE_VALUE, MissionPlanRoute, Page } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import {
    DEFAULT_SORTING,
    FilterConditions,
    Mission,
    MissionDetails,
    MissionSearchError,
    MissionsListWithPages,
    Paging,
    Sorting,
} from "../models/mission-search.models";
import { MissionSearchApiService } from "../services/mission-search-api.service";
import { MissionSearchActions } from "./mission-search.actions";

export interface MissionSearchStateModel {
    filterConditions: FilterConditions | undefined;
    sorting: Sorting | undefined;
    missions: Mission[] | undefined;
    missionsPages: Page | undefined;
    mission: MissionDetails | undefined;
    flightPurposes: Record<string, string> | undefined;
    route: MissionPlanRoute | undefined;
    isProcessing: boolean;
    getMissionsError: MissionSearchError | undefined;
    getMissionError: MissionSearchError | undefined;
    getMissionRouteError: MissionSearchError | undefined;
}

const defaultState: MissionSearchStateModel = {
    filterConditions: undefined,
    sorting: undefined,
    missions: undefined,
    missionsPages: undefined,
    mission: undefined,
    flightPurposes: undefined,
    route: undefined,
    isProcessing: false,
    getMissionsError: undefined,
    getMissionError: undefined,
    getMissionRouteError: undefined,
};

@State<MissionSearchStateModel>({
    name: "missionSearch",
    defaults: defaultState,
})
@Injectable()
export class MissionSearchState {
    @Selector()
    public static getMissionsError(state: MissionSearchStateModel): MissionSearchError | undefined {
        return state.getMissionsError;
    }

    @Selector()
    public static filterConditions(state: MissionSearchStateModel): FilterConditions | undefined {
        return state.filterConditions;
    }

    @Selector()
    public static sorting(state: MissionSearchStateModel): Sorting | undefined {
        return state.sorting;
    }

    @Selector()
    public static missions(state: MissionSearchStateModel): Mission[] {
        return state.missions ?? [];
    }

    @Selector()
    public static missionsPages(state: MissionSearchStateModel): Page | undefined {
        return state.missionsPages;
    }

    @Selector()
    public static isProcessing(state: MissionSearchStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static getMissionError(state: MissionSearchStateModel): MissionSearchError | undefined {
        return state.getMissionError;
    }

    @Selector()
    public static mission(state: MissionSearchStateModel): MissionDetails | undefined {
        return state.mission;
    }

    @Selector()
    public static route(state: MissionSearchStateModel): MissionPlanRoute | undefined {
        return state.route;
    }

    @Selector()
    public static getMissionRouteError(state: MissionSearchStateModel): MissionSearchError | undefined {
        return state.getMissionRouteError;
    }

    @Selector()
    public static flightPurposes(state: MissionSearchStateModel): Record<string, string> | undefined {
        return state.flightPurposes;
    }

    constructor(private readonly missionSearchApi: MissionSearchApiService) {
        if (missionSearchApi === undefined) {
            throw new Error("Initialize MissionSearchModule with .forRoot()");
        }
    }

    @Action(MissionSearchActions.SetFilterConditions)
    public setFilterConditions(context: StateContext<MissionSearchStateModel>, action: MissionSearchActions.SetFilterConditions) {
        context.patchState({
            filterConditions: action.filterConditions,
        });
    }

    @Action(MissionSearchActions.SetMissionsOrder)
    public setMissionsOrder(context: StateContext<MissionSearchStateModel>, action: MissionSearchActions.SetMissionsOrder) {
        context.patchState({
            sorting: action.sorting,
        });
    }

    @Action(MissionSearchActions.FetchMissions)
    public fetchMissions(context: StateContext<MissionSearchStateModel>, action: MissionSearchActions.FetchMissions) {
        context.patchState({
            isProcessing: true,
        });

        const { filterConditions, sorting, missionsPages } = context.getState();
        const paging: Paging = {
            size: missionsPages?.pageSize ?? action.maxResultsSize ?? MAX_PAGE_SIZE_VALUE,
            page: missionsPages?.pageNumber ?? 0,
        };

        if (!filterConditions) {
            context.patchState({
                missions: [],
                missionsPages: {
                    pageNumber: 0,
                    pageSize: paging.size,
                    totalElements: 0,
                },
                getMissionsError: undefined,
                isProcessing: false,
            });

            return;
        }

        return this.missionSearchApi.fetchMissions(filterConditions, sorting ?? DEFAULT_SORTING, paging).pipe(
            tap((result: MissionsListWithPages) =>
                context.patchState({
                    missions: result.content,
                    missionsPages: result.pages,
                    getMissionsError: undefined,
                })
            ),
            catchError((error) => {
                context.patchState({ missions: undefined, getMissionsError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(MissionSearchActions.GetMission)
    public getMission(context: StateContext<MissionSearchStateModel>, action: MissionSearchActions.GetMission) {
        context.patchState({
            mission: undefined,
            isProcessing: true,
        });

        return this.missionSearchApi.getMission(action.missionId).pipe(
            tap((result: { missionDetails: MissionDetails; flightPurposes: Record<string, string> | undefined }) =>
                context.patchState({
                    mission: result.missionDetails,
                    flightPurposes: result.flightPurposes,
                    getMissionError: undefined,
                })
            ),
            catchError((error) => {
                context.patchState({ getMissionError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(MissionSearchActions.GetMissionRoute)
    public getMissionRoute(context: StateContext<MissionSearchStateModel>, action: MissionSearchActions.GetMissionRoute) {
        context.patchState({
            route: undefined,
            isProcessing: true,
        });

        return this.missionSearchApi.getMissionRoute(action.routeId).pipe(
            tap((result: MissionPlanRoute) => {
                const { mission } = context.getState();

                return context.patchState({
                    route: result,
                    mission: mission
                        ? {
                              ...mission,
                              distance: result.estimatedDistance ?? 0,
                              isRoutePathBased: result.isPathBased ?? false,
                          }
                        : undefined,
                    getMissionRouteError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({ getMissionRouteError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(MissionSearchActions.ClearMissionData)
    public clearMissionData(context: StateContext<MissionSearchStateModel>) {
        context.patchState({
            route: undefined,
            mission: undefined,
        });
    }
}
